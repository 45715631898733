@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
:root{
    --Portfolio-gradient: linear-gradient(90deg, #FA0006 0%, #2300F0 100%);
    --Portfolio-White-50: #FAFAFF;
    --Portfolio-Apple-Black: #000;
    --Portfolio-Grey-100: #ECECEC;
    /* --Sub-banner-gradient : radial-gradient(23.11% 23.11% at 50% 50%, #EFF1FF 0%, #000 100%); */
    --Sub-banner-gradient : radial-gradient(80% 40% at 50% 50%, #EFF1FF 0%, #000 100%);
    /* transition: .5s all ease-in-out; */
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
}
*{
    cursor: url('./img/FInal_Curoser.svg'),auto;
}
/* *{
    transition: all 0.3s ease-in-out;   
} */
.custom-header{
    display: flex;
    padding: 12px 59.5px 12px 58.5px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: var(--Portfolio-gradient);
    min-width: 353px;
    transition: .5s all ease-in-out;
}
.header-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    transition: .5s all ease-in-out;
}
.header-menu li a{
    color: var(--Portfolio-White-50);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    transition: .5s all ease-in-out;
}
.duration-500
{
  transition-duration: .5s;
}
.transition-all
{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  transition-duration: 150ms;
}
.-top-24
{
    top: -100px !important;
    /* top: 100% !important; */
}
.custom-header:hover{
    transform: scale(1.1);
}
.header-menu li a:hover, .header-menu li a.active{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
@media screen and (max-width: 768px) {
    .header-menu li a:hover{
        font-weight: 300;
    }
}
.header-parent{
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 99999;
    /* height: fit-content; */
}

/* #banner Section */
.home-page-banner-section *{
    overflow-x: visible;
}
.home-page-banner-section{
    position: relative;
    /* height: 100vh; */
    padding: 80px 0px 0px;
    background: var(--Portfolio-Apple-Black);
}
.banner-font{
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 220px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    letter-spacing: -8.8px;
}
.left-side-slider{
    top: -33px;
    right: 12%;
}
.right-side-slider{
    bottom: 150px;
    right: 16vw;
    z-index: 9;
    text-align: right;
}
.banner-text{
    color: var(--Portfolio-Grey-100);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 20px;
    padding-bottom: 81px;
}
.banner-inner-section{
    position: absolute;
    bottom: 0px;
    /* display: flex; */
}
.banner-text-parent{
    margin-top: auto;
}
.full-video{
    overflow: hidden;
    clip-path: inset(1px 1px);
}


/* innovation section */
.Innovation-section{
    padding: 40px 0px;
    position: relative;
    width: 100%;
    scroll-snap-align: start;
}
.section-heading{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
    background: var(--Portfolio-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.banner-sub-text{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--Sub-banner-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: -10px;
}
.border-btn{    
    padding: 8px 24px 8px 24px;
    min-width: 223px;
    border-radius: 96px;
    border: 1px solid var(--Portfolio-Black, #1F1F1F);
    background-color: #fff;
    margin: 24px auto 0px auto;
    color: var(--Portfolio-Black, #1F1F1F);
/* Desktop/Poppins/Light 16 */
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: block;
    position: relative;
    cursor: pointer;
    transition: .5s;
}
.border-btn:hover{
    background: var(--Portfolio-gradient) !important;
    color: #fff !important;
    border: 1px solid transparent !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
}
/* .speech-section */
.speech-section{
    background: var(--Portfolio-gradient);
    padding: 100px 0px;
    text-align: center;
}
.magic-img-div{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    overflow: hidden;
}
.magic-img{
    max-width: 100%;
}
.magics-head{
    color: var(--Portfolio-White, #FFF);
    text-align: center;
    /* Desktop/Poppins/Regular 128 */
    font-family: 'Poppins', sans-serif;
    font-size: 8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -10.24px;
}
.magics--sub{
    color: var(--Portfolio-White, #FFF);
    text-align: center;
    /* Desktop/Poppins/Regular 24 */
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.magics--sub span{
    font-size: 56px;
}
.create-drawer{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.protfolio-section{
    padding-top: 56px;
    background: var(--Portfolio-Apple-Black);
}
.glimpse-section{
    background: var(--Portfolio-Apple-Black);
    padding-bottom: 40px;
}
.about-raaj{
    padding: 140px 0px;
    position: relative;
    overflow: hidden;
}
.abut-raaj-txt{
    color: var(--Portfolio-Grey-200, #8F8F8F);
text-align: center;
/* Desktop/Poppins/Light 24 */
font-family: 'Poppins', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.abut-raaj-txt span{
    background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
/* Desktop/Poppins/Light 24 */
font-family: 'Poppins', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.footer-inner-section{
    display: flex;
    padding: 0px 80px 24px 80px;
    justify-content: center;
    align-items: center;
}
.raaj-mailid{
    text-align: right;
/* Desktop/Poppins/Regular 32 */
font-family: 'Poppins', sans-serif;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: normal;
background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.raaj-mailid span{
    background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Desktop/Poppins/Medium 16 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.footer-menu li a{
    color: var(--Portfolio-Black, #1F1F1F);
/* Desktop/Poppins/Light 16 */
font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.footer-menu li a:hover{
    background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
}

/* mobile version */
@media only screen and (max-width: 768px) {
    .header-parent{
        bottom: 0px;
        align-items: end !important;
        top: 87% !important;
    }
    .footer-inner-section{
        padding: 0px 15px 24px 15px;
    }
    .raaj-mailid{
        font-size: 20px;
        margin-top: 20px;
    }
}

/* .protfolio-section */

.sub-page-banner{

}
.glimpse-section{
    overflow: hidden;
    position: relative;
    width: 100%;
}
@media only screen and (max-width: 769px) {
    .banner-font-slide{
        overflow: hidden;
        position: relative;
        width: 100%;
        top: 130px;
    }
    .-top-24
        {
            bottom: -100px !important;
        }
    .banner-font{
        font-size: 110px;
    }
    .home-page-banner-section{
        height: 100vh;
    }
    .right-side-slider{
        bottom: -7px;
        overflow: hidden;
    }
    .section-heading{
        font-size: 24px;
        letter-spacing: -0.24px;
        padding-bottom: 0px !important;
    }
    .magics-head{
        letter-spacing: -0.24px;
    }
    .magics--sub span
    {
        font-size: 28px;
    }
    .magics--sub
    {
        color: var(--Portfolio-White, #FFF);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .banner-text{
        font-size: 14px;
    }
    .abut-raaj-txt {
        font-size: 14px;
        margin-top: 2%;
    }
    .abut-raaj-txt span{
        font-size: 14px;
    }
}
.position-fixed{
    position: fixed !important;
}

.modal-text{
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 140px 0px;
}
.modal-text p{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--Portfolio-Apple-Black, #000);
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.modal-text h4{
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ant-modal-close{
    display: none;
}
.ant-modal-content{
    padding: 0px;
    background: linear-gradient(90deg, #FFF 5.12%, #C9C0FF 17.18%, #FFF 58.92%, #FF7070 131.47%);
    border-radius: 0px;
}
.mt-5{
    margin-top: 150px;
}
.mobile-screen-modal .ant-modal-content{
    border-radius: 32px;
    border: 1px solid var(--Portfolio-gradient, #FA0006);
    background: var(--Portfolio-White-50, #FAFAFF);
    box-shadow: 0px 4px 16px 16px #242424;
    padding: 37px 17px;
    text-align: center;
}
.mobile-screen-modal h4{
    background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
}
.mobile-screen-modal p{
    color: var(--Portfolio-Black, #1F1F1F);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 24px;
}
.mobile-btn{
    padding: 8px 16px;
    border-radius: 8px;
background: var(--Portfolio-gradient, linear-gradient(90deg, #FA0006 0%, #2300F0 100%));
color: var(--Portfolio-White-50, #FAFAFF);
text-align: center;
/* Desktop/Poppins/Regular 16 */
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: 0px;
margin-top: 24px;
}

.modal-content {
    /* background: #f5f0f0; */
    /* padding: 20px; */
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 999;
    transform: translateY(100%);
    max-width: 1120px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    box-shadow:
      0 4px 20px 0 rgb(0 0 0 / 10%),
      0 1px 2px 0 rgb(0 0 0 / 6%);
    height: 21rem;
    border: 2px solid #1F1F1F;
    border-top-width: 20px;
    border-radius: 10px;
    height: 80vh;
  }
  .traficlights{
    
  }
    .close-icon{
        
    }
  .modal-content::-webkit-scrollbar {
    width: 8px;
}
.modal-content::-webkit-scrollbar-track {
    background: #f5f0f0;
          /* color of the tracking area */
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background-color: #8F8F8F;
    border-radius: 20px; 
  }
  
  .modal-content.slide-down {
    animation: slide-down 0.5s ease-in-out forwards;
  }
  
  .modal-content.slide-up {
    animation: slide-up 0.5s ease-out forwards;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
  }
  .modal-container{
    position: relative;
    overflow: hidden;
  }
  
  .provideFeedback {
    font-size: 20px;
  }
  
  .feedbackModalHeader {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    /* margin-top: 0.8rem; */
  }
  .modal-overlay {
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      transition: opacity 0.3s ease;
    }
    .usx-grid .ux-aps{
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        border-radius: 6px;
        border: 2px solid var(--Portfolio-Black, #1F1F1F);
        border-top-width: 15px;
        box-shadow: 0px 0px 10.27px 0px rgba(0, 0, 0, 0.05);
        display: block;
        border-image-slice: 1;
    }
    .view-txt{
        width: calc(100% - 26px);
        height: calc(100% - 39px);
        display: grid;
        position: absolute;
        top: 26px;
        left: 13px;
        opacity: 0.8;
        background: var(--Portfolio-Black, #1F1F1F);
        color: #fff;
        align-items: center;
        justify-content: center;
        color: var(--Portfolio-White, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        visibility: hidden;
        border: 2px solid transparent;
        border-top-width: 15px;
    }
    .usx-grid .ux-aps::after{
        content: "";
        background: url('./img/Traffic_Lights.png') no-repeat;
        position: absolute;
        position: absolute;
        top: 16px;
        left: 25px;
        width: 27px;
        height: 9px;
        background-size: 100% 100%;
        z-index: 999;
    }
    .header-text{
        content: "iConnect";
        color: rgba(235, 235, 245, 0.30);
        text-align: center;
        font-family: Poppins;
        font-size: 6.159px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        position: absolute;
        top: 17px;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .usx-grid{
        overflow: hidden;
        padding: 12px;
        position: relative;
    }
    .usx-grid .ux-aps img{
        transition: all 0.5s ease-in-out;
        overflow: hidden;
    }
    .usx-grid .ux-aps:hover img{
        scale: 1.1;
        overflow: hidden;
        object-position: 55%;
        transform: scale(1.1) translate(0, 5%);
    }
    .usx-grid .ux-aps:hover .view-txt{
        visibility: visible;
    }
    .usx-grid .ux-aps:hover{
        border-image-source: linear-gradient(90deg, #FA0006 0%, #2300F0 100%);
    }

    /* // contact form */
    .conatcform form input:focus + label,.conatcform form textarea:focus + label{
        color: #fff;
        font-size: 20px;
        top: 15px;
        left: 15px;
    }
    .conatcform form input:focus + label + .animeline,.conatcform form textarea:focus + label + .animeline{
        width: 100%;
    }

    .conatcform form input:not(:placeholder-shown) + label,.conatcform form textarea:not(:placeholder-shown) + label{
        visibility: hidden;
    }
    .Exclusive-sec::before{
        content: "";
        clear: both;
        display: table;
    }
    .text-warpper h4{
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 84px;
        /* letter-spacing: -10.24px; */
        background: var(--Portfolio-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .text-warpper p{
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #8F8F8F;
    }
    .text-warpper p span{
        color: #fff;
    }
    .content-warpper{
        box-shadow: 0px 40px 100px 0px #00000066;
        background: linear-gradient(0deg, rgba(56, 56, 56, 0.44), rgba(56, 56, 56, 0.44)),
        radial-gradient(175.4% 50% at 50% -4950%, rgba(100, 210, 255, 0.05) 0%, rgba(0, 0, 0, 0) 12%);
        border-radius: 24px 24px 32px 32px;
        position: relative;
    }
    .content-warpper::before{
        content: "";
        width: 80%;
        height: 2px;
        background: url('./img/div.glare-item-top.png');
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
    }
    .innovation-sec{
        background: var(--Portfolio-Apple-Black);
        margin-top: -1px;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .challenge-wrapper{
        padding: 156px 0px;
        padding-top: 0px;
    }
    .challege-head h4{
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 84px;
        /* letter-spacing: -10.24px; */
        background: var(--Portfolio-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .challege-head p{
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #8F8F8F;
        max-width: 930px;
        margin: auto;
        padding-top: 24px;
    }
    .challege-head p span{
        color: #fff;
    }
    .challenge-wrapper-img{
        position: relative;
    }
    .challenge-wrapper-img::after{
        content: "";
        width: 75px;
        height: 166px;
        position: absolute;
        background: url('./img/Frame_595.png');
        background-repeat: no-repeat;
        background-size: 100%;
        top: 50%;
        transform: translate(0px, -28%);
        right: -75px;
        clear: both;
        z-index: 999999;
    }
    .challenge-wrapper-img::before{
        content: "";
        width: 75px;
        height: 166px;
        position: absolute;
        background: url('./img/Frame_594.png');
        background-repeat: no-repeat;
        background-size: 100%;
        top: 50%;
        transform: translate(0px, -28%);
        left: -75px;
        clear: both;
        z-index: 999999;
    }
    .bottom-gradient{
        background: linear-gradient(90deg, #fa0006, #2300f0);
        background: var(--Portfolio-gradient);
        border-radius: 0 0 32px 32px;
        height: 257px;
        overflow: visible;
        position: relative;
        width: 100%;
        margin-bottom: 262px;
    }
    .bottom-gradient img{
        position: absolute;
        width: 100%;
    }
    .bottom-floating{
        height: 262px;
        background: #000;   
    }
    .notetext{
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: italic;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 738;
        color: #8F8F8F;
    }
    @media screen and (max-width:768px) {
        .bottom-gradient{
            height: 84px;
        }
    }
    section.innovation-sec * {
        overflow: initial;
    }
    .remove-bf-af::after{
        display: none;
    }
    .remove-bf-af::before{
        display: none;
    }
    .innovation-sec::after{
        content: "";
        width: 100%;
        height: 354px;
        background-color: #fff;
        position: absolute;
        bottom: 0px;
        z-index: -1;
    }
    .fun-facts{
        padding: 156px 0px;
        position: relative;
    }
    .fun-facts *{
        overflow-x: visible;
    }
    .fun-facts p{
        max-width: 930px;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        margin: auto;
        color: #8F8F8F;
    }
    .black-gradeiant {
        background: radial-gradient(14.89% 14.89% at 50% 50%, #EFF1FF 0%, #000 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width:768px) {
        .banner-sub-text{
            font-size: 18px;
            line-height: 25px;
            margin-top: 2%;
        }
        .banner-btn-text{
            margin-bottom: 56px;
        }
        .hGnbp .clip-first span{
            font-size: 14px;
        }
        .parrallax-banner-fir
        {
            padding: 0px !important;
        }
        .center-animation{
            display: none !important;
        }
        .usx-grid{
            padding: 4px;
        }
        .usx-grid .ux-aps::after{
            display: none;
        }
        .challege-head p{
            font-size: 16px;
            line-height: 24px;
            padding-top: 4px;
            text-align: left;
        }
        .text-warpper h4{
            font-size: 32px;
        }
        .challege-head h4{
            font-size: 32px;
            line-height: 40px;
            text-align: left;
        }
        .challege-head{
            padding: 14px;
        }
        .fun-facts p {
            max-width: 930px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            margin: auto;
            color: #8F8F8F;
            margin-top: 2%;
        }
        .fun-facts{
            padding-top: 4px;
        }
    }